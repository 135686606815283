select.customSelect {
    -webkit-appearance: none;
    appearance: none;
    color: #91919F;
}

select.customSelect {
    background-image: url("../../../../public/assets/settings/select_arrow.png");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
}
.productsList-container {

    .productsList-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
        flex-shrink: 1;
        @media(max-width:767px) {
            flex-direction: column;
            flex-wrap: initial;
            justify-content:initial;
        }

        .productsList-header-left {

            span {
                color: #A0AEC0;
                font-family: 'Nunito', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

            p {
                color: #2D3748;
                font-family: 'Nunito', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                /* 19.6px */
            }
        }

   
        .productsList-header-right {
            Button {
                color: #FFF;
                font-family: 'Nunito', sans-serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                // line-height: 150%;

            }
        }

    }
    .productlist-table {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.10);
        padding: 19px;
        margin-bottom: 15px;

        @media(max-width :1023px){
            flex-direction: column;
            gap: 0;
        }

        &:hover{
            // border: 1px solid #CA1818;
            font-style: normal;
        }

        .table-content {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 30px;
            
            
            .first {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 50px;
                text-align: start;

                h1 {
                    color: #000232;
                    font-family: 'Lato', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                p {
                    color: rgba(0, 2, 50, 0.47);
                    font-family: 'Lato', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .second {
                text-align: center;

                p {
                    color: #87889E;
                    font-family: 'Lato', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            @media(max-width:600px){
                .second{
                    display:none;
                }
            }
            .firstdata {
                width: 250px;
            }

            .third {
                text-align: end;

                h1 {
                    color: #000232;
                    text-align: right;
                    font-family: 'Lato', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                p {
                    color: rgba(0, 2, 50, 0.47);
                    text-align: right;
                    font-family: 'Lato', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            

            .table-content-image {

                // background: #EBF3F8;
                object-fit: contain;

                img {
                    // max-width: 100px;
                    //object-fit: contain;
                    border-radius: 11px;
                }
            }

            .table-content-data {

                .table-content-data-header {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 150px;

                    p {
                        color: #718096;
                        font-family: 'Nunito', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    span {
                        border-radius: 5px;
                        background: #FF6B02;
                        color: #FFF;
                        text-align: center;
                        font-family: 'Nunito', sans-serif;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;

                        padding-left: 6px;
                        padding-right: 6px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        /* 15.4px */
                    }
                }

                .table-content-data-details {
                    display: flex;
                    gap: 50px;
                    flex-direction: row;
                    // flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: baseline;
                    @media only screen and (min-width: 768px) and (max-width: 1350px) {
                        gap: 15px;
                    }

                    @media(max-width :1100px){
                        gap:0px;
                        flex-direction: column;
                        justify-content: initial;
                        align-items: initial;
                    }

                    @media(max-width :639px){
                        flex-direction: column;
                        align-items: start;
                        gap: 0;
                        .table-content-data-left,
                        .table-content-data-right{
                            width: 100%;
                        }
                    }

                    p {
                    }

                    span {
                    }

                    .left{
                        min-width: 260px;
                    }

                    .right{
                        min-width: 220px;
                    }

                    @media(max-width:1420px){
                        .left{
                            min-width: 200px;
                        }

                        .right{
                            min-width: 200px;
                        }
                    }

                    @media(max-width:1380px){
                        .left{
                            min-width: 180px;
                            max-width: 189px;
                        }

                        .right{
                            min-width: 200px;
                        }
                    }
                }
            }
        }
        @media(max-width:850px) {
            .table-content{
                flex-direction: column;
                gap: 0;
                margin-top: 10px;
            }
        }
        .table-action-barcode {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            .icon {
                font-size: 22px;
                color: #407BFF;
                margin-right: 0px;
                margin-left: 18px;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 5px;
                padding-bottom: 3px;
                border-radius: 12px;
            }

            .table-action-delete {
                Button {
                    color: #E53E3E;
                    font-family: 'Nunito', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    /* 15px */
                }
            }

            .table-action-edit {
                Button {
                    color: #2D3748;
                    font-family: 'Nunito', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;

                }
            }

        }

        .table-action {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
            min-width: 185px;

            @media(max-width :1023px){
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .icon {
                font-size: 22px;
                color: #407BFF;
                margin-right: 0px;
                margin-left: 18px;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 5px;
                padding-bottom: 3px;
                border-radius: 12px;
            }

            .table-action-delete {
                Button {
                    color: #E53E3E;
                    font-family: 'Nunito', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    /* 15px */
                }
            }

            .table-action-edit {
                Button {
                    color: #2D3748;
                    font-family: 'Nunito', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;

                }
            }

        }
    }
    @media(max-width :767px){
        .productlist-table{
            flex-direction: column;
        }
    }
    .custom-loader {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 8px solid;
        border-color: #766DF4 #0000;
        animation: s1 1s infinite;
        margin: 12px auto;
    }

    @keyframes s1 {
        to {
            transform: rotate(.5turn)
        }
    }

}
.Barcode-container {
    border-radius: 27px;
    background: #FFF;
    

    .title-link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 15px;

        h1 {
            color: #000232;
            font-family: 'Lato', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: hidden;

        }

        a {
            color: #034AFF;
            text-align: right;
            font-family: 'Lato', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
        }

    }
    @media(max-width:600px){
        .title-link {
           justify-content:start;
           align-items: center;
           gap:120px;
           
        }
        .title-link h1{
            font-size: 18px;
        }
    }
}

@media(max-width:850px) {
    .table-content-data{
        margin-top: 10px;
    }
}
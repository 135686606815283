.first-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
}

.first-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.first-right-card {
    background: #ffffff;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    max-width: 618px;
    width: 100%;
}
.first-log-img {
    /* text-align: -webkit-center; */
    display: inline-block;
}

.first-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}

.first-log-img img {
    max-width: 230px;
    max-height: 230px;
    width: 100%;
    height: 100%;
}

.first-form-main p {
    /* margin-left: 100px;
    margin-right: 100px; */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 54px;
    /* or 129% */

    text-align: center;
    text-transform: capitalize;

    color: #102048;
}

.continue-form-button {
    background: #034aff;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #fcfcfc;
    width: 70%;

    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
}

.firstform-console {
    text-align: center;
}

.firstform-console p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 10px;

    /* Base/Light/Light 20 */

    color: #91919f;
}

/* .first-right-card {
    position: relative;
} */

.first-progressbar-signup {
    position: absolute;
    bottom: 5%;
    left: 45%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.first-right-card {
    min-height: 580px;
    height: 90vh;
}

.continue-button {
    /* margin-bottom: 10px; */
    display: none;
}

.indicator-one {
    width: 111px;
    height: 25px;
    left: 1008px;
    top: 862px;

    background: #102048;
    border-radius: 29px;
}

.indicator-two {
    width: 25px;
    height: 25px;
    left: 1131px;
    top: 862px;

    background: #c4c4c4;
    border-radius: 29px;
    cursor: pointer;
}

.indicator-three {
    width: 25px;
    height: 25px;
    left: 1131px;
    top: 862px;

    background: #c4c4c4;
    border-radius: 29px;

    cursor: pointer;
}

@media only screen and (max-width: 960px) {
    .first-container {
        grid-template-columns: 1fr;
    }

    .first-left {
        display: none;
    }

    .first-form-main p {
        font-size: 24px;
        line-height: 34px;
    }

    .firstform-console p {
        font-size: 10px;
        line-height: 14px;
    }

    .continue-button {
        margin-bottom: 10px;
        display: contents;
    }

    .continue-button button {
        margin-bottom: 10px;
    }

    .first-progressbar-signup {
        position: absolute;
        bottom: 5%;
        /* left: 20%; */

        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .first-right {
        justify-content: center;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Override autofill styles for input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {

  background-color: transparent !important; /* Set background to transparent */
    border: 1px solid white; /* Add a white border */
    -webkit-text-fill-color: black !important; /* Set text color to black */
}
.p-10 {
  padding: 0px;
}

/* Override autofill styles for password input fields */
input[type="password"]:-webkit-autofill,
input[type="password"]:-webkit-autofill:hover,
input[type="password"]:-webkit-autofill:focus,
input[type="password"]:-webkit-autofill:active {
  background-color: transparent !important; /* Set background to transparent */
    border: 1px solid white; /* Add a white border */
    -webkit-text-fill-color: black !important; /* Set text color to black */
}
input::placeholder {
  color: #999; /* Customize the placeholder text color */
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modal:not(dialog:not(.modal-open)), .modal::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    animation: modal-pop 0.2s ease-out;
}


.custom-loader {
  width:50px;
  height:50px;
  border-radius:50%;
  border:8px solid;
  border-color:#766DF4 #0000;
  animation:s1 .5s infinite;
  margin: 12px auto;
}
@keyframes s1 {to{transform: rotate(.5turn)}}
@keyframes s2 {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }}

.custom-loader-logo {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 252, 252, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  img,div,span {
    animation: s2 .5s infinite;
    width: 100px;
  }
}
.dashboard,
.mainDashboard {
  padding: 30px 30px 30px 30px;
  background: #ebf1ff;
  gap: 20px;
  justify-content: center;
}

.dashboard .dashboardContainer,
.mainDashboard .dashboardContainer {
  flex: 6;
}

@media only screen and (max-width: 1200px) {
  .dashboard,
  .mainDashboard {
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 667px) {
  .dashboard {
    /* background: transparent !important; */
    // background: white;
  }
}

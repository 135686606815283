.chart-info-title p {
    color: var(--gray-gray-700, #102048);
    font-size: 12px;
    font-family: "Nunito";
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 0 !important;
}

.chart-info-details p {
    color: #A0AEC0;
    font-size: 8px;
    font-family: "Nunito";
    line-height: 140%;
}

.chart-info-details span {
    color: #102048;

}
#pdfConentAreasA3 {
  /* padding: 5% 7%; */
  font-family: Arial;
  /* display: flex; */
  /* justify-content: center;  */
  /* align-items: center;  */
  height: 100vh;
  border: 1px solid red;

}

/* @media print {
  .print-table th {
    padding: 3px 5px;
    border: 1px solid black;
    background-color: rgb(227, 227, 145);
  }
  .print-table {
    width: 100%;
    border-collapse: collapse;
  }
} */

/* .headingTable{
  margin: 0 0 20px 0;
}

.headingTable td{
  font-size: 12px;
}

.headingPDf{
  text-align: center;
  margin: 0 0 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

img.logoImage{
  width: 120px;
  margin: 0 0 10px 0;
}

.pdfConentAreasA3 table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.pdfConentAreasA3 table td {
  margin: 0;
}
#pdfContentTHead{
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(11, 20, 20);
} */
.sidebar {
    flex-shrink: 1;
    // border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 980px !important;
    border-radius: 25px;

    background: #F8F9FA;
    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;

        @media(max-width:1024px){
            padding: 40px 20px;
        }
        .logo {
            font-size: 20px;
            font-weight: bold;
            color: #6439ff;
        }
    }

    .leftRight{
        // @media(max-width:1279px){
        //     display: none;
        // }
        @media(max-width:767px){
            display: none;
        }
    }

    .logoImg {
        width: 66px;
        height: 55px;
        float: left;
        position: absolute;
        left: 21px;
        // top: 1.7%;
        top: 1%;
    }

    .center {

        // padding-left: 40px;


        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;


            li {
                display: flex;
                align-items: center;
                margin-top: 24px;
                padding: 12px 5px;
                color:#A0AEC0;
                border-radius: 16px;
                transition: .2s;
                cursor: pointer;

                .minus,.plus{
                    color: #A0AEC0;
                    &:active{
                        color:#407BFF !important;
                    }
                }
                &:hover,
                &.active {
                    background-color: #407BFF;


                    a {
                        // color: #2D3748;
                        color: white;
                    }


                    .icon {
                        padding: 4px 4px 2px 4px;
                        border-radius: 8px;
                        background: white;
                        // padding: 8.9px;
                        // margin-left: 12px;
                        // padding-right: 15px;
                        // padding-top: 5px;
                        // padding-bottom: 3px;
                        color: #407BFF;
                        // margin-right: 23px;
                    }
                    .minus{
                        color:white;
                    }
                    .plus{
                        color:white;
                    }

                }

                &:active {
                    background-color: #407BFF;

                    a {
                        color: white;
                    }


                    .icon {
                        // margin-left: 8px !important;
                        border-radius: 8px;
                        padding: 4px 4px 2px 4px;
                        // padding: 8.9px;
                        // padding-left: 15px;
                        // padding-right: 15px;
                        // padding-top: 5px;
                        // padding-bottom: 3px;
                        color: #407BFF;
                        background:white;
                        // margin-right: 23px;
                    }


                }

                .icon {
                    font-size: 18px;
                    // color: #407BFF;
                    //margin-right: 23px;
                    // padding: 8.9px;
                    margin-left: 8px;
                    // background: #fff;
                    border-radius: 8px;
                    // padding: 12px 10px 12px 10px;
                    padding: 4px 4px 2px 4px;

                }
                .small-icon{
                    @media(max-width:960px){
                        padding: 2px 2px 1px 2px;
                        font-size: 12px;
                        width: 25px;
                        height: 25px;
                    }
                }
                // span {
                //     font-size: 13px;
                //     font-weight: 600;
                //     color: #888;
                //     margin-left: 22px;
                // }

                a {
                    text-decoration: none;
                    // color: var(--gray-gray-400, #A0AEC0);
                    font-family: Nunito;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    @media(max-width:960px){
                        // padding: 10px 0px;
                        font-size: 12px;
                        text-align: left;
                    }
                }

                h1 {

                    color: var(--gray-gray-700, #2D3748);
                    font-family: Helvetica;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    padding-left: 35px;


                }
            }
            .dropdown-parent{

                .minus,.plus{
                color: #407BFF !important;
                }
                &:hover,&:active{
                    transition: .2s;
                    // background: #ECF2FF;
                color: #407BFF;
                a{
                    color: #407BFF !important;
                    span{
                        transition: .2s;
                        color: #407BFF !important;
                    }
                }
                .icon{
                    background-color: transparent;
                }
                .plus{
                    transition: .2s;
                    color: #407BFF !important;
                }
                .minus{
                    transition: .2s;
                    color: #407BFF !important;
                }
                }
            }
            .li-signout{
            &:hover{
                background: #F8F9FA;
            }
            .icon-red{
                color: red !important;
                background: #F8F9FA !important;
                &:hover{
                background: #F8F9FA !important;
                }
            }
            .title-red{
                color: red;
                &:hover{
                    color: red;
                    background: #F8F9FA;
                }
            }
            }

        }

        }

    .menuCollapse{
        .parentLink{
            padding-left: 0 !important;
            padding-right: 0 !important;
            li.active{
                padding-left: 0 !important;
                padding-right: 0 !important;
                .icon{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    margin: 0 auto !important;
                }
            }

            li{
                display: block;
                .icon{
                    margin-left: 0;
                }
            }
        }
    }
}

.middle {

    margin: 20px;

    .middle_card {

        text-align: center;
        // background-color: white;
        background-image: url("../../../public/needBackground.svg");
        padding: 20px;
        border-radius: 12px;
        margin-top: 146px;
        background-size: cover;
        width: 245px;
        .card_text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
        }

        h1 {
            color: var(--black-amp-white-white, #FFF);
            font-family: Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            // line-height: 140%;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: flex-start;


            // padding: 10px;

            color: var(--black-amp-white-white, #FFF);
            font-family: Helvetica;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            // line-height: 150%;

        }

        .card_button {
            background-color: #fff;
            border: none;

            border-radius: 12px;

            font-weight: 600;
            margin-top: 10px;
            object-fit: contain;
        }

        .needicon {
            text-align: left;
            padding-bottom: 25px;

        }

    }
}

@media(max-width:767px){
    .sidebar{
        display: none;
    }
}
@media(max-width:1280){
    .sidebar{
        padding: 20px;
    }
    .top img{
        display: hidden;
    }
    .needicon{
        display: hidden;
    }
}

.moon {
    background: #EBF1FF;
    border-radius: 50%;
    /* I set 2 shadows where the first one doesn't have blur and spread for firefox fix. */
    box-shadow: inset 0px 19px #F8F9FA, inset 0px 16px 1px 1px #F8F9FA;
    -moz-box-shadow: inset 0px 19x #F8F9FA, inset 0px 16px 1px 1px #F8F9FA;
  }
  .moon-2 {
    background: #EBF1FF;
    border-radius: 50%;
    /* I set 2 shadows where the first one doesn't have blur and spread for firefox fix. */
    box-shadow: inset 0px 19px #F8F9FA, inset 0px 16px 1px 1px #F8F9FA;
    -moz-box-shadow: inset 0px 19x #F8F9FA, inset 0px 16px 1px 1px #F8F9FA;
  }
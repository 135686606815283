.pdfConentAreas3 {
    padding-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight:800 ;
    color:rgb(0, 0, 0);
 }
 
 .headingTable{
     margin: 0 0 20px 0;
 }
 tbody,th{
     font-family: Arial, Helvetica, sans-serif;
     font-weight:800 ;
     color:rgb(0, 0, 0);
 }
 
 .headingTable td{
     font-size: 12px;
 }
 
 .headingPDf{
     text-align: center;
     margin: 0 0 10px 0;
     font-size: 22px;
 }
 .invoice-customer{
     font-family: Arial, Helvetica, sans-serif;
     font-weight:800 ;
     color:rgb(0, 0, 0);
 }
 
 img.logoImage{
     width: 120px;
     margin: 0 0 10px 0;
 }
 
 .pdfConentAreas3 table {
     width: 100%;
     border-spacing: 0;
     border-collapse: collapse;
 }
 
 .pdfConentAreas3 table td {
     margin: 0;
 }
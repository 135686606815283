.pdfConentAreas2 {
    padding: 5% 7%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight:800 ;
    color:rgb(0, 0, 0);
}

.headingTable{
    margin: 0 0 20px 0;
}

.headingTable td{
    font-size: 12px;
}

.headingPDf{
    text-align: center;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
}

img.logoImage{
    width: 120px;
    margin: 0 0 10px 0;
}

.pdfConentAreas2 table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.pdfConentAreas2 table td {
    margin: 0;
}
tbody,th{
    font-family: Arial, Helvetica, sans-serif;
    font-weight:800 ;
    color:rgb(0, 0, 0);
}
.menu{
    /* hover:bg-[#407BFF] p-2 text-[13px] hover:text-[#FFF] font-[500] font-nunito rounded-[6px] flex gap-3
                    bg-[#F2F2F2] text-[#000] */

    display:block;
    color: #000;
    padding:8px 8px 8px 35px;
    font-size:13px;
    font-weight:500;
    font-family: font-nunito;
    border-radius:6px;
    background-color:#F2F2F2;
    background-image: url("../../../../public/assets/settings/shop-settings-2.svg");
    background-repeat: no-repeat;
    background-position: 8px center;
    margin:0 0 10px 0;
}

.menu.active,
.menu:hover{
    background-color: #407BFF;
    color: #fff;
    background-image: url("../../../../public/assets/settings/shop-settings-1.svg");
}
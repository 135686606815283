/* .conatiner {
    display: grid;
    padding: 30px 30px 30px 30px;
    background: #EBF1FF;
    grid-template-columns: 346px 1fr;
    justify-content: center;
}

.sidebar {
    max-width: 346px;
    width: 100%;
    
}

.pagecontent {
    margin-left: 30px;

}

.main-content {
    border-radius: 25px;
    background: #FFF;
    padding: 30px 30px 30px 30px;
    margin-top: 30px;
} */



.main-content {
    border-radius: 25px;
    background: #FFF;
    
    margin-top: 30px;
}

.table-calculator {
    margin-top: 30px;
}

.table-calculator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 15px;
}
@media only screen and (max-width: 667px) {
    .main-content {
        margin-top: 10px !important;
    }
    #total-cards{
        margin-bottom: 10px;
    }
    #graph-cards{
        margin-top: 10px;
    }
   
}

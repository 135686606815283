.topheader {
    border-radius: 25px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 26px 26px 26px 26px;
}

.person {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
}

.setting-notification {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
}
.notifications-icon i{
   font-size: 35px;
   color:rgb(223, 241, 22);
}
.setting-notification img {
    cursor: pointer;
}

.person-name {
    padding-left: 18px;
}

.settings-icon {
    padding-left: 15px;
}

.pos-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-color: #f0f4ff; /* Green background */
  border-radius: 50%; /* Circle shape */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  */
  cursor: pointer;
  margin-right: 15px;
}

.pos-icon::before {
  content: 'POS';
  font-size: 14px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-family: 'Arial', sans-serif;
}
.pur-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-color: #f0f4ff; /* Green background */
  border-radius: 50%; /* Circle shape */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  */
  cursor: pointer;
  margin-right: 15px;
}

.pur-icon::before {
  content: 'PUR';
  font-size: 14px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-family: 'Arial', sans-serif;
}

.person-name p {
    color: #102048;
    font-size: 12px;
    font-family: "Nunito";
    font-weight: 600;
    margin-bottom: 0 !important;
}

.person span {
    color: #102048;
    font-size: 20px;
    font-family: "Nunito";
    font-weight: 600;
}
.menu-bar{
    display: grid;
    grid-template-columns: 0.6fr;

}
.menu-bar p{
    display: none;
}

@media (max-width: 767px) {
    .topheader {
        flex-direction: row;

        align-items: center;

        padding: 15px;

        justify-content: space-between;
        border-radius: 40px;
        background: #034AFF;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.35);
        /* max-width: 360px; */
        margin: 0 auto;
        max-width: 90vw;
    }
    .personimage{
        display: flex;
        align-items: center;
    }
    .person-name {
        text-align: center;
        padding-left: 0;

        margin-left: 10px;
    }
    .person-name p{
        color: rgba(255, 255, 255, 0.63);
        margin-right: 23px;
    }
    .person-name span{
        color:#fff;

    }

    .settings-icon {
        display: none;
    }
    .notifications-icon img{
        display: none;
    }
    .menu-bar p{
        display: block;

        border-radius: 15px;
        padding: 5px;
        text-align: center;
    }
    .pos-icon {
      display: none;
    }
}
.RecentOrdersTable-container {
    border-radius: 27px;
    background: #FFF;
    padding:25px;
    

    .title-link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 15px;

        h1 {
            color: #000232;
            font-family: 'Lato', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        a {
            color: #034AFF;
            text-align: right;
            font-family: 'Lato', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
        }

    }
    @media(max-width:600px){
        .title-link {
           justify-content:start;
           align-items: center;
           gap:120px;
           
        }
        .title-link h1{
            font-size: 18px;
        }
    }

    .table-conatiner {
        .hrtable {
            height: 1px;
            border-radius: 10px;
            background: rgba(0, 93, 174, 0.11);
            // width: 100%;
        }

        .table-data {
            display: grid;
            padding-top: 15px;
            align-items: start;
            // padding-bottom: 15px;

            .first {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 14px;
                text-align: start;

                h1 {
                    color: #000232;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                p {
                    color: rgba(0, 2, 50, 0.47);
                    font-family: 'Lato', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .second {
                text-align: center;

                p {
                    color: #000232;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .due{
                p{
                    color: #000232;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal; 
                }
            }
            
            .third {
                text-align: end;

                h1 {
                    color: #000232;
                    text-align: right;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: blue;
                    text-align: right;
                    font-family: 'Lato', sans-serif;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                }
            }
.fourth{
    h1 {
        color: #000232;
        text-align: right;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p {
        color: blue;
        text-align: right;
        font-family: 'Lato', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }
}
        }
    }
    @media (max-width : 600px){
        .table-conatiner{
            .hrtable{
                //display: none;
            }
           
        } 
    }
}

@media(max-width:600px){
    .RecentOrdersTable-container{
        max-width: 600px;
        //margin-right: 100px;
        padding: 15px;
    }
@media(max-width:600px){
    .images img{
        width:30px;
        height: 30px;
    }
}
}
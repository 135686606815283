html, body {height: 100%; margin: 0; padding: 0;}
#root,
.App{
    height: 100%;
}

.form-container{
    overflow-y: scroll;
    overflow-x: hidden;
}

.form,
.form-container,
.body{
    height: 100%;
}

.login-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    /* height:100vh; */
    overflow-x: hidden;
    overflow-y: scroll;
}

.login-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-left img {
    max-width: 473px;
    max-height: 626px;
}

.login-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
    max-height: 964px;
}

.login-right-card {
    background: #ffffff;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    width: 100%;
    max-width: 618px;
    height:93vh;
    min-height:654px;
}

.login-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}
.login-log-img {
    text-align: -webkit-center;
}

.login-log-img img {
    /* text-align: -webkit-center; */
    display: inline-block;
    text-align: center;
}

.login-log-img p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    align-items: center;
    text-align: center;

    color: #91919f;
}

.login-form-main {
    margin-left: 100px;
    margin-right: 100px;
}

/* #components-form-demo-normal-login .login-form {
    max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
} */

.login-form-button {
    background: #034aff;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #fcfcfc;
    width: 100%;

    padding-top: 17px;
    padding-bottom: 17px;
}

.login-right-card .ant-btn {
    height: auto !important;
}

.login-form input[type='email'] {
    background: #ffffff;
    border: 1px solid #f1f1fa;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
    transition: none;
}

.login-form input[type='password'] {
        background: #ffffff;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
    
    padding-left: 10px;
        transition: none;
}
.login-form input[type='text'] {
        background: #ffffff;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
   
    padding-left: 10px;
        transition: none;
}

.loginform-console {
    text-align: center;
}

.loginform-console a {
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #034aff;
    cursor: pointer;
}

.loginform-console p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #91919f;
    margin-top: 15px;
}

.warning-message {
    color: red;
    margin-bottom: 20px;
    font-size: 16px;
}
.success-message {
    color: green;
    margin-bottom: 20px;
    font-size: 16px;
}

@media only screen and (max-width: 960px) {
    .login-container {
        grid-template-columns: 1fr;
    }

    .login-left {
        display: none;
    }

    .login-form-main p {
        font-size: 24px;
        line-height: 34px;
    }

    .loginform-console p {
        font-size: 10px;
        line-height: 14px;
    }

    /* .continue-button {
        margin-bottom: 10px;
        display: contents;
    }

    .continue-button button {
        margin-bottom: 10px;

    }

    .login-progressbar-signup {
        position: absolute;
        bottom: 5%;
     
        left: 50%;
        transform: translate(-50%, -50%);

    } */

    .login-right {
        justify-content: center;
    }

    .login-form-main {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.footers {
    p {
        color: #A0AEC0;
        font-family: "Nunito", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

    }

    span {
        color: #407BFF;
        font-family: "Nunito", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
}
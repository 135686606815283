.dpnone {
    display: none;
}

.form-container {
    position: relative;
    margin: 0 auto;
    background: #032843;
    height:100%;
}
.body {
    max-width: 1440px;
    margin: 0 auto;
}

.footer {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1254px;
    display: flex;
}

.footer button {
    background: #D9D9D9;
    border-radius: 31px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* line-height: 54px; */
    /* identical to box height, or 300% */
    text-align: center;
    text-transform: capitalize;

    color: #000000;
    padding:10px 50px 10px;
    border: none;
    margin: 10px;
}
@media only screen and (max-width: 1280px) {

    .footer {
        max-width: 1024px;
    }

}

@media only screen and (max-width: 960px) {

    .footer {
        display: none;
    }

}
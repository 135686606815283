.graphchart-container {

}


.graphchart-graph-progress-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 45px;

}

.graphchart-graph-progress-graph {
    margin-bottom: 23px;
}

.graphchart-graph-progress-graph img {
    width: 100%;
}

.graphchart-graph-progress-info-title p {
    color: var(--gray-gray-700, #2D3748);
    font-size: 12px;
    font-family: "Nunito";
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 0 !important;
}

.graphchart-graph-progress-info-details p {
    color: #A0AEC0;
    font-size: 8px;
    font-family: "Nunito";
    line-height: 140%;
}

.graphchart-graph-progress-info-details span {
    color: #48BB78;

}

.graphchart-graph-progress-container-title p {
    color: var(--gray-gray-400, #A0AEC0);
    font-size: 12px;
    font-family: "Nunito";
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0 !important;
    margin-top: 3px;

}

.graphchart-graph-progress-container-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -6px;
}

.graphchart-graph-progress-container-mainprogress p {
    color: var(--gray-gray-700, #2D3748);
    font-size: 12px;
    font-family: "Nunito";
    font-weight: 600;
    line-height: 140%;
}
.balance-chart{
    /* background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%); */
    color : white;
    border-radius: 10px;
    width:100%;
}

@media(max-width: 600px){
    .graphchart-container {
        grid-template-columns:  1fr; 
        grid-gap: 15px;
        margin-top:30px;
    }
    .balance-chart{
        background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%);
        color : white;
        border-radius: 10px;
        width:100%;

    }
   
    
}
@media(min-width: 768px) and ( max-width : 840px){
 .graphchart-graph-progress-container-mainprogress p {    
        font-size: 10px;
    }
}
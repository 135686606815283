/* CSS for CalculatorPopup component */
.calculator-popup {
    position: fixed;
    top: 50%;
    transform: translate(-20%, -50%);
    display: none; /* Hidden by default */
    border-radius: 15px;
    right: 10px;
    z-index: 999999;
  }
  
  .calculator-popup.open {
    display: block; /* Display when isOpen is true */
  }
  
  /* .close-button {
    position: absolute;
    top: -15px;
    right: -8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 30px;

  } */
  .close-button i{
    color:rgba(235, 99, 31, 0.892);
    position: absolute;
    top:25px;
    right:27px;
    font-size:25px;
  }
  
  /* CSS for ChatPopup component */
  .chat-popup-container {
    position: relative;
  }
  
  .calculator-icon {
    cursor: pointer;
    /* Add your icon styles here */
  }
  

  @media (max-width: 667px){
    .calculator-popup {
        left:45%;
        top: 60%;
        transform: translate(-40%, -65%);
        right: 5px;
    }
    .close-button i{
      color:blue;
      padding-right: 10px;
    }
}
.navbar {
    flex-shrink: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    border-radius: 25px;
    background: #F8F9FA;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 9999;
    padding-left: 0px;
    transition: left 1s;
    width: 98%;


    .top {
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-top: 39px;
        position: relative;
        width: 100%;

        .logo {
            font-size: 15px;
            font-weight: bold;
            color: #6439ff;
        }
        .closeIcon{
            font-size: 35px;
            color: #407BFF;
            position: absolute;
            right: 10px;
            top: -26px;
        }
    }

    .center {

        // padding-left: 40px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                display: flex;
                align-items: center;
                margin-top: 20px;
                padding: 10px 20px 10px 12px;
                cursor: pointer;
                gap: 8px;
                border-radius:12px;
                color: #A0AEC0;

                &:hover {
                    // background-color: #407BFF;
                    // border-radius: 15px;

                    a {
                        // color: white;
                        color: #A0AEC0;
                    }


                    .icon {
                        // background: white;
                        // padding: 8.9px;
                        padding: 5px;
                        color: #A0AEC0;
                        // margin-right: 23px;
                    }


                }

                &:active {
                    // background-color: #407BFF;
                    // border-radius: 15px;

                    a {
                        // color: white;
                    }


                    .icon {
                        // padding: 8.9px;
                        padding:5px;
                        // color: #fff;
                        // margin-right: 23px;
                    }


                }

                .icon {
                        // padding: 8.9px;
                        padding:5px;
                        // margin-right: 23px;
                    }

                // span {
                //     font-size: 13px;
                //     font-weight: 600;
                //     color: #888;
                //     margin-left: 22px;
                // }

                a {
                    text-decoration: none;
                    font-family: Nunito;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;

                }

                h1 {

                    color: var(--gray-gray-700, #2D3748);
                    font-family: Helvetica;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    padding-left: 15px;


                }
            }


        }

    }
}


.middle {

    margin: 20px;

    .middle_card {

        text-align: center;
        // background-color: white;
        background-image: url("../../../public/needBackground.svg");
        padding: 20px;
        border-radius: 12px;
        margin-top: 146px;
        background-size: cover;

        .card_text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
        }

        h1 {
            color: var(--black-amp-white-white, #FFF);
            font-family: Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            // line-height: 140%;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: flex-start;


            // padding: 10px;

            color: var(--black-amp-white-white, #FFF);
            font-family: Helvetica;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            // line-height: 150%;

        }

        .card_button {
            background-color: #fff;
            border: none;

            border-radius: 12px;

            font-weight: 600;
            margin-top: 10px;
            object-fit: contain;
        }

        .needicon {
            text-align: left;
            padding-bottom: 25px;

        }

    }
}

.active-mobile-menu li{
    background-color: #407BFF !important;
    color: white !important;
}

.active-mobile-menu li .icon{
    color:#407BFF !important;
    background-color: white !important;
    border-radius: 8px;
}
.emailverify-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
}

.emailverify-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emailverify-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
}

.emailverify-right-card {
    background: #ffffff;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    max-width: 618px;
    width: 100%;
}
.emailverify-log-img {
    /* text-align: -webkit-center; */
    display: inline-block;
}

.emailverify-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}

.emailverify-log-img img {
    max-width: 230px;
    max-height: 230px;
    width: 100%;
    height: 100%;
}

.emailverify-form-main p {
    /* margin-left: 100px;
    margin-right: 100px; */
    font-family: 'Nunito';
    color: var(--base-dark-dark-100, #0d0e0f);
    text-align: center;
    font-size: 37px;

    font-weight: 700;
}

.emailverifyform-console {
    text-align: center;
}

.emailverifyform-console p {
    color: #292b2d;
    text-align: center;
    font-size: 17px;
    font-family: 'Inter';
    font-weight: 500;
}

.emailverify-right-card {
    height: 90vh;
    min-height:550px;
}

/* .emailverify-right-card {
    position: relative;
} */

.email-continues-button {
    margin-top: 100px;
}

@media only screen and (max-width: 960px) {
    .emailverify-container {
        grid-template-columns: 1fr;
    }

    .emailverify-left {
        display: none;
    }

    .emailverify-form-main p {
        font-size: 24px;
        line-height: 34px;
    }

    .emailverifyform-console p {
        font-size: 10px;
        line-height: 14px;
    }

    .emailverify-progressbar-signup {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .emailverify-right {
        justify-content: center;
    }
}

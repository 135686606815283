/* .conatiner {
    display: grid;
    padding: 30px 30px 30px 30px;
    background: #EBF1FF;
    grid-template-columns: 346px 1fr;
    justify-content: center;
}

.sidebar {
    max-width: 346px;
    width: 100%;
    
}

.pagecontent {
    margin-left: 30px;

}

.main-content {
    border-radius: 25px;
    background: #FFF;
    padding: 30px 30px 30px 30px;
    margin-top: 30px;
} */

.main-content {
    border-radius: 25px;
    background: #FFF;
    padding: 30px 30px 1px 30px;
    margin-top: 30px;

    @media(max-width:667px){
        /* max-width:350px;
        margin-left:auto;
        margin-right:auto; */
        /* padding: 0; */
        padding: 15px 15px 1px 15px;
    }
}

.mainDashboard{
    .main-content{
        padding: 0;
        background: transparent;
    }
}

.table-calculator {
    margin-top: 30px;
}

.table-calculator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    /* gap: 15px; */
    @media(max-width:767px){
        gap: 0;
    }
}

@media(max-width:639px){
    .recentTableContent{
        padding: 0;
        .RecentOrdersTable-container{
            padding: 0;
        }
    }
}
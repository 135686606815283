.total-card-container {
  display: grid;
  grid-gap: 2%;
  background: #ffffff;
  border-radius: 25px;
  padding: 30px 28px 28px 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.total-card-container-data {
  border-radius: 13px;
  box-shadow: 0px 0px 6px 0px rgba(16, 32, 72, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 5% 25px 5%;
  min-height: 125px;
}

.total-card-desc p {
  color: #fff;
  font-size: 18px;
  font-family: "Nunito";
  font-weight: 700;
  padding-top: 7px;
}

.total-card-title p {
  color: rgba(255, 255, 255, 0.67);
  font-size: 12px;
  font-family: "Nunito";
  font-weight: 600;
}

.total-card-percantage-info p {
  color: #fff;
  font-size: 11px;
  font-family: "Nunito";
  font-weight: 800;
  padding-bottom: 35px;
}

.total-card-percantage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  align-content: space-between;
}

@media (max-width: 600px) {
  .total-card-container {
    grid-template-columns: 1fr;
  }

  .total-card-container-data {
    padding: 10px 10px 10px 10px;
  }

  .total-card-percantage {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .total-card-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .cards-container {
    padding: 0 25px;
  }
}
.otp-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
}

.otp-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-left img {
    max-width: 473px;
    max-height: 626px;

}

.otp-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
}

.otp-right-card {
    background: #FFFFFF;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    max-width: 618px;
    width: 100%;
}

.otp-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;


}

.otp-log-img p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    align-items: center;
    text-align: center;

    color: #91919F;
}

.otp-form-main {
    margin-left: 100px;
    margin-right: 100px;
}

/* #components-form-demo-normal-otp .otp-form {
    max-width: 300px;
}

#components-form-demo-normal-otp .otp-form-forgot {
    float: right;
}

#components-form-demo-normal-otp .ant-col-rtl .otp-form-forgot {
    float: left;
}

#components-form-demo-normal-otp .otp-form-button {
    width: 100%;
} */

.otp-form-button {
    background: #034AFF;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #FCFCFC;
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 50px;
    border: none;
}

.otp-form-main .ant-btn {
    height: auto !important;
}

.otp-form-main .ant-form-item {
    margin-bottom: 0 !important;
}


.otp-form {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.otp-form input[type="text"] {
    background: #FFFFFF;
    border: 1px solid #F1F1FA;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.otpform-console {
    text-align: center;
}

.otpform-console a {
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #034AFF;
    cursor: pointer;
}

.otpform-console p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #91919F;
    margin-top: 15px;
}

.hr {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 1px;
    background-color: rgba(3, 74, 255, 0.65);
    margin-left: 30%;
    margin-right: 30%;
}

.otp-desctitle p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 50px;
    text-align: center;

    /* Base/Dark/Dark 100 */

    color: #0D0E0F;
    margin-bottom: 28px;
}

.otp-description p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    align-items: center;
    text-align: center;

    color: #91919F;

}

.otp-right-card {
    height: 90vh;
    min-height:500px;
}

.otp-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.otp-box input {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* max-width: 54px;
    max-height: 54px;
    height: 100%;
    width: 100%; */
    height: 54px;
    width: 54px;
}

.otp-timer p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    color: #034AFF;
    margin-top: 14px;
}

.otp-info p {
    /* Body/Body 1 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    /* Base/Dark/Dark 25 */

    color: #292B2D;
}

.resend-otp p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;

    color: #034AFF;
}

@media only screen and (max-width: 960px) {
    .otp-container {

        grid-template-columns: 1fr;

    }

    .otp-left {
        display: none;
    }

    .otp-form-main p {

        font-size: 24px;
        line-height: 34px;

    }

    .otpform-console p {

        font-size: 10px;
        line-height: 14px;

    }

    /* .continue-button {
        margin-bottom: 10px;
        display: contents;
    }

    .continue-button button {
        margin-bottom: 10px;

    }

    .otp-progressbar-signup {
        position: absolute;
        bottom: 5%;
     
        left: 50%;
        transform: translate(-50%, -50%);

    } */

    .otp-right {

        justify-content: center;

    }

    .otp-form-main {
        margin-left: 25px;
        margin-right: 25px;
    }

    .otp-box input {

        height: 34px;
        width: 34px;
    }

    .otp-form-main p {
        font-size: 14px;
        line-height: 20px;
    }


}
.home .homeContainer {
    flex: 6;
}

.main-content {
   /*  border-radius: 25px;
    background: #FFF;
    padding: 30px 30px 30px 30px;
    margin-top: 30px; */
}
.homePage{
    background: transparent;
    padding:0;
    @media (max-width: 767px) {
        padding:0;
    }
}

.table-calculator {
    margin-top: 30px;
}

.table-calculator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 0;
    @media (max-width : 667px){
        margin:0px auto;
    }
}
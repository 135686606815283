body {
  overflow-x: hidden;
}

.scroll-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: none;
}
.scroll-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

.searchList{
  display: flex;
}
.slider-list {

}
.sliderWrapper {
 display: flex;
}

.sliderWrapper .slider {
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.sliderArea {
  margin: 0 auto;
  padding-top: 5%;
  /* margin-bottom: 0px; */
  height: 271px;
  padding-left: 1.5%;
}
.parent-sliderArea div div.slick-list {
  height: 242px;
  /* added padding */
  padding-left: 16px;
  padding-top:20px;
  display: block;
  position: relative;
}
.single-product img{
  min-height:125px;
  max-height:125px;
}
.single-product:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.foodList {
  margin-top: -10px;
}
.single-product-item {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  background: #FFF;
     /* float: left;
    width: 48%; */
    /* margin-right: 10px; */
}
.single-product-item.selected {
  height: 28vw;
  /* margin-right: 10px;
  float: right; */
}


@media(max-width:767px){
  .sliderArea {
    margin: 0 auto;
    padding-top: 5%;
    max-width: 100%;
    /* margin-bottom: 0px; */
    /* height: auto; */
    padding-left: 1.5%;
    margin-bottom: 14px !important;
    justify-content: center;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 6px;
}

.custom_shadow{
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
.slider-area{
  margin-bottom: -10px;
}
.slick-initialized .slick-slide {
  margin-bottom: 22px;
}

/* Add this CSS to prevent digits from overflowing */
.responsive-number {
  overflow-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

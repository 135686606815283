.pdfConentAreas {
    /* padding: 1% 2%; */
    font-family: 'Hiragino Kaku Gothic ProN','poppins',Helvetica,Arial,sans-serif,YuGothic,"游ゴシック Medium";
}

.headingTable{
    margin: 0 0 20px 0;
}

.headingTable td{
    font-size: 12px;
}

.headingPDf{
    text-align: center;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
}

img.logoImage{
    width: 120px;
    margin: 0 0 10px 0;
}

.pdfConentAreas table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.pdfConentAreas table td {
    margin: 0;
}

#pdfContentTHead{
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(11, 20, 20);
}
.securitypin-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    border-radius: 0px;
}

.securitypin-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.securitypin-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
}

.securitypin-right-card {
    background: #ffffff;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    max-width: 618px;
    width: 100%;
    overflow-y: auto;
}

.securitypincard {
    text-align: -webkit-center;
}

.securitypin-log-img {
	display: inline-block;
}

.securitypin-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}

.securitypin-log-img img {
    max-width: 180px;
    max-height: 180px;
    width: 100%;
    height: 100%;
    margin-top: 5%;
}

.securitypin-form-main p {
    /* margin-left: 100px;
    margin-right: 100px; */
    font-family: 'Nunito';
    color: var(--base-dark-dark-100, #0d0e0f);
    text-align: center;
    font-size: 37px;

    font-weight: 700;
}

.securitypin-console {
    text-align: center;
}

.securitypinform-console p {
    color: #91919f;
    text-align: center;
    font-size: 17px;
    font-family: 'Inter';
    font-weight: 500;
}

.securitypin-right-card {
    height: 90vh;
}

/* .securitypin-right-card {
    position: relative;
} */

.email-continues-button {
    margin-top: 100px;
}

.securitypin-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.securitypin-box input {
    background: #d9d9d9;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 45px;
    /* max-width: 54px;
    max-height: 54px;
    height: 100%;
    width: 100%; */
    height: 38px;
    width: 38px;
}

.forget-pin {
  text-align: center;
}

.forget-pin a {
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #034aff;
  cursor: pointer;
}

.forget-pin p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #91919f;
  margin-top: 15px;
}

@media only screen and (max-width: 960px) {
    .securitypin-container {
        grid-template-columns: 1fr;
    }

    .securitypin-left {
        display: none;
    }

    .securitypin-form-main p {
        font-size: 24px;
        line-height: 34px;
    }

    .securitypin-console p {
        font-size: 10px;
        line-height: 14px;
    }

    .securitypin-progressbar-signup {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .securitypin-right {
        justify-content: center;
    }
}

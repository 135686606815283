.passverify-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center; 
    border-radius: 0px;
}

.passverify-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.passverify-left img {
    max-width: 473px;
    max-height: 626px;
    margin: 0 auto;
}

.passverify-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
}

.passverify-right-card {
    background: #ffffff;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding:10px 65px 10px;
    max-width: 618px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.passverify-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}

.passverify-log-img p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    align-items: center;
    text-align: center;

    color: #91919f;
}

.passverify-log-img {
    margin-top: 20px;
    padding-bottom: 50px;
    /* text-align: -webkit-center; */
    display: inline-block;
    margin: 0 auto;
}

.passverify-form-main {
    margin-left: 100px;
    margin-right: 100px;
}

/* #components-form-demo-normal-passverify .passverify-form {
    max-width: 300px;
}

#components-form-demo-normal-passverify .passverify-form-forgot {
    float: right;
}

#components-form-demo-normal-passverify .ant-col-rtl .passverify-form-forgot {
    float: left;
}

#components-form-demo-normal-passverify .passverify-form-button {
    width: 100%;
} */

.passverify-form-button {
    background: #034aff;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #fcfcfc;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.passverify-form-main .ant-btn {
    height: auto !important;
}

.passverify-form-main .ant-form-item {
    margin-bottom: 0 !important;
}

.passverify-form {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}
.passverify-form input[type='password'] {
    background: #ffffff;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 10px;
    transition: none;
}

.passverify-form input[type='text'] {
    background: #ffffff;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 10px;
    transition: none;
}

.passverifyform-console {
    text-align: center;
}

.passverifyform-console a {
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #034aff;
    cursor: pointer;
}

.passverifyform-console p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #91919f;
    margin-top: 15px;
}

.hr {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 1px;
    background-color: rgba(3, 74, 255, 0.65);
    margin-left: 30%;
    margin-right: 30%;
}

.passverify-desctitle p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 27px;
    /* or 119% */

    text-align: center;
    text-transform: capitalize;

    color: #102048;
}

.passverify-description p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    
    align-items: center;
    text-align: center;

    color: #91919f;
    padding-top: 10px;
    padding-bottom: 20px;
}

.passverify-right-card {
    /* height: 95vh; */
    min-height:570px;
}

.password-checkbox {
    /* Body/Body 3 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    display: flex;
    align-items: center;

    color: #000000;
    padding-bottom: 30px;
    text-align: left;
}

@media only screen and (max-width: 960px) {
    .passverify-container {
        grid-template-columns: 1fr;
    }

    .passverify-left {
        display: none;
    }

    .passverify-form-main p {
        font-size: 24px;
        line-height: 34px;
    }

    .passverifyform-console p {
        font-size: 10px;
        line-height: 14px;
    }

    /* .continue-button {
        margin-bottom: 10px;
        display: contents;
    }

    .continue-button button {
        margin-bottom: 10px;

    }

    .passverify-progressbar-signup {
        position: absolute;
        bottom: 5%;
     
        left: 50%;
        transform: translate(-50%, -50%);

    } */

    .passverify-right {
        justify-content: center;
    }

    .passverify-form-main {
        margin-left: 25px;
        margin-right: 25px;
    }
}

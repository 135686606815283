.emailverifyforget-container {
    background: #032843;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 0px;
}

.emailverifyforget-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emailverifyforget-left img {
    max-width: 473px;
    max-height: 626px;

}

.emailverifyforget-right {
    margin: 30px 30px 30px 30px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    align-items: center;
}

.emailverifyforget-right-card {
    background: #FFFFFF;
    box-shadow: -18px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    justify-content: center;
    text-align: center;
    padding-bottom: 53px;
    padding-top: 53px;
    max-width: 618px;
    width: 100%;
}

.emailverifyforget-log-img h1 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #102048;
    padding-top: 30px;
}

.emailverifyforget-log-img img {

    margin-bottom: 55px;
    display: inline-block;
}

.emailverifyforget-log-img {

    margin-bottom: 55px;
}

.emailverifyforget-log-img p {
    /* font-family: 'Nunito', sans-serif;
    color: #0D0E0F;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 10px; */


    font-family: Nunito;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;


}

.emailverifyforget-form-main {
    margin-left: 100px;
    margin-right: 100px;
}

/* #components-form-demo-normal-emailverifyforget .emailverifyforget-form {
    max-width: 300px;
}

#components-form-demo-normal-emailverifyforget .emailverifyforget-form-forgot {
    float: right;
}

#components-form-demo-normal-emailverifyforget .ant-col-rtl .emailverifyforget-form-forgot {
    float: left;
}

#components-form-demo-normal-emailverifyforget .emailverifyforget-form-button {
    width: 100%;
} */

.emailverifyforget-form-button {
    background: #034AFF;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #FCFCFC;
    width: 100%;

    padding-top: 17px;
    padding-bottom: 17px;
}

.emailverifyforget-right-card .ant-btn {
    height: auto !important;
}

.emailverifyforget-form input[type="email"] {
    background: #FFFFFF;
    border: 1px solid #F1F1FA;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
}

.emailverifyforgetform-console {
    text-align: center;
}

.emailverifyforgetform-console a {
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #034AFF;
    cursor: pointer;
}

.emailverifyforgetform-console p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #91919F;
    margin-top: 15px;
}

.emailverifyforget-right-card {
    height: 90vh;
}

@media only screen and (max-width: 960px) {
    .emailverifyforget-container {

        grid-template-columns: 1fr;

    }

    .emailverifyforget-left {
        display: none;
    }

    .emailverifyforget-form-main p {

        font-size: 24px;
        line-height: 34px;

    }

    .emailverifyforgetform-console p {

        font-size: 10px;
        line-height: 14px;

    }

    /* .continue-button {
        margin-bottom: 10px;
        display: contents;
    }

    .continue-button button {
        margin-bottom: 10px;

    }

    .emailverifyforget-progressbar-signup {
        position: absolute;
        bottom: 5%;
     
        left: 50%;
        transform: translate(-50%, -50%);

    } */

    .emailverifyforget-right {

        justify-content: center;

    }

    .emailverifyforget-form-main {
        margin-left: 25px;
        margin-right: 25px;
    }


}
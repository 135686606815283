.calculate-icon i{
    background-color: rgba(240, 59, 39, 0.999);
    padding:11px 15px;
    border-radius: 100%;
    color:aliceblue;
    position: fixed;
    top: 90%;
    left: intial;
    right: -10px;
    transform: translate(-50%, -50%);
    z-index: 999999;
}
.calculate-icon i:hover{
    cursor: pointer;
    background: black;
    color:white;
}
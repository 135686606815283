.RecentOrdersTable-container {
    border-radius: 27px;
    background: #FFF;
    padding:30px;
    @media(max-width:667px){
        padding: 0;
        // max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .title-link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 15px;

        h1 {
            color: #000232;
            font-family: 'Lato', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        a {
            color: #034AFF;
            text-align: right;
            font-family: 'Lato', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
        }

    }
    @media(max-width:667px){
        .title-link {
           justify-content:space-between;
           align-items: center;
           gap:12px;
           
        }
        .title-link h1{
            font-size: 18px;
        }
    }

    .table-conatiner {
        .hrtable {
            height: 1px;
            border-radius: 10px;
            background: rgba(0, 93, 174, 0.11);
            // width: 100%;
        }

        .table-data {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding: 7px 0;
            // padding-bottom: 15px;
            @media(max-width:768px){
                //grid-template-columns: 1fr 1fr;
            }

            .first {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 14px;
                text-align: start;

                h1 {
                    color: #000232;
                    font-family: 'Lato', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    font-size: 14px;
                    @media(max-width:667px){
                        font-size: 12px;
                    }
                }

                p {
                    color: rgba(0, 2, 50, 0.47);
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .second {
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    color: #000;
                    font-family: 'Nunito', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .fourth {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                h1 {
                    color: #000;
                    text-align: right;
                    font-family: 'Nunito', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @media(max-width:667px){
                        font-size: 12px;
                    }
                }

                p {
                    color: #87889E;
                    text-align: right;
                    font-family: 'Nunito', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

        }

        .recent-table-data {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding: 7px 0;
            // padding-bottom: 15px;
            @media(max-width:639px){
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .first {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 14px;
                text-align: start;

                h1 {
                    color: #000232;
                    font-family: 'Nunito', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    font-size: 14px;
                    @media(max-width:667px){
                        font-size: 12px;
                    }
                }

                p {
                    color: #87889E;
                    font-family: 'Nunito', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .second {
                display: flex;
                align-items: center;
                text-align: center;
                p {
                    flex: 1;
                    color: #000;
                    font-family: 'Nunito', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .fourth {
                text-align: center;
                @media(max-width:639px){
                    display: none;
                }
                h1 {
                    color: #000;
                    font-family: 'Nunito', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @media(max-width:667px){
                        font-size: 12px;
                    }
                }

                p {
                    color: #87889E;
                    font-family: 'Nunito', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .fifth{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap:8px;
                @media (max-width : 360px){
                    flex-direction: column;
                    align-items: end;
                }
                .viewBtn{
                    border-radius: 5px;
                    border: 1px solid #407BFF;
                    color: #407BFF;
                    padding: 3px 7px;
                    font-family: Nunito;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .editBtn{
                    border-radius: 5px;
                    background: #407BFF;
                    padding: 4px 10px;
                    color: #FFF;
                    font-family: Nunito;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

        }
    }
    @media (max-width : 600px){
        .table-conatiner{
            .hrtable{
                //display: none;
            }
           
        } 
    }
}

@media(max-width:600px){
    .RecentOrdersTable-container{
        //max-width: 600px;
        //margin-right: 100px;
    }
    @media(max-width:600px){
        .images img{
            width:30px;
            height: 30px;
        }
    }
}
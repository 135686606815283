.pdfConentAreasPad {
    /* padding: 5% 7%; */
    font-family: Arial;
}

.headingTable{
    margin: 0 0 20px 0;
}

.headingTable td{
    font-size: 12px;
}

.headingPDf{
    text-align: center;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
}

img.logoImage{
    width: 120px;
    margin: 0 0 10px 0;
}

.pdfConentAreasPad table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.pdfConentAreasPad table td {
    margin-bottom: 1000px;
    /* margin: 0; */
}
#pdfContentTHeadPad{
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    /* color: rgb(11, 20, 20); */
    color: red;
}
.RecentSalesTable  .title-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 15px;
}
.RecentSalesTable .title-link h1 {
    color: #000232;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.RecentSalesTable .title-link a {
    color: #034AFF;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}
@media(max-width:600px){
    .RecentSalesTable .title-link {
        justify-content:start;
        align-items: center;
        gap:120px;
        
    }
    .RecentSalesTable .title-link h1{
        font-size: 18px;
    }
}
.sellingcard-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 11px;
}

.sellingcard-card {
    border-radius: 15px;
    background-color: #f6f8fa;
    /* padding: 5px; */
}

.sellingcard-img img {
    border-radius: 15px;
}

.sellingcard-info {
    border-radius: 15px 0px 15px 15px;
    background: #536487;
    padding: 11px;
    width: 100%;
}

.sellingcard-title p {
    color: #FFF;
    font-size: 9px;
    font-family: "Nunito";
    font-weight: 600;
    margin-bottom: 0 !important;
}

.sellingcard-title span {

    color: rgba(255, 255, 255, 0.56);
    font-size: 8px;
    font-family: "Nunito";
    font-weight: 600;
}

.sellingcard-hr {

    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    background-color: #8194B9;


}

.sellingcard-price p {
    color: #FFF;
    font-size: 10px;
    font-family: "Nunito";
    font-weight: 700;
    margin-bottom: 0 !important;
}

.sellingcard-title-info p {
    color: #000232;
    font-size: 22px;
    font-family: "Lato";
    font-weight: 600;
}
.sliderAreaHome {
    margin: 0 auto;
    /* padding-top:10px; */
    max-width: 100%;
    /* margin-bottom: 0px; */
    max-height: 290px;
    padding-left: 1.5%;
    justify-content: center;
}
.sellingcard-container1 {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-gap: 11px;
}
.sliderAreaHome div.slick-list {
  /* height: 220px; */
  max-height: 280px;
  display: block;
  position: relative;
  padding-left: 10px;
  
}

.sliderAreaHome div.slick-list div.slick-track{
    max-height: 280px;
    padding-top: 10px;
}

@media(max-width:700px){
  .sliderAreaHome {
      margin: 0 auto;
      padding-top: 5%;
      max-width: 100%;
      padding-left: 1.5%;
      justify-content: center;
  }
}